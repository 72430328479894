import React from "react"
import PngOne from "../../../assets/images/raster/pages/reverse-asin-lookup/Section/reverse-section-1.png"
import PngOne_2x from "../../../assets/images/raster/pages/reverse-asin-lookup/Section/reverse-section-1@2x.png"
import PngOneMob from "../../../assets/images/raster/pages/reverse-asin-lookup/Section/reverse-section-1@mob.png"
import PngOneMob_2x from "../../../assets/images/raster/pages/reverse-asin-lookup/Section/reverse-section-1@mob2x.png"
import PngTwo from "../../../assets/images/raster/pages/reverse-asin-lookup/Section/reverse-section-2.png"
import PngTwo_2x from "../../../assets/images/raster/pages/reverse-asin-lookup/Section/reverse-section-2@2x.png"
import PngTwoMob from "../../../assets/images/raster/pages/reverse-asin-lookup/Section/reverse-section-2@mob.png"
import PngTwoMob_2x from "../../../assets/images/raster/pages/reverse-asin-lookup/Section/reverse-section-2@mob2x.png"
import PngThree from "../../../assets/images/raster/pages/reverse-asin-lookup/Section/reverse-section-3.png"
import PngThree_2x from "../../../assets/images/raster/pages/reverse-asin-lookup/Section/reverse-section-3@2x.png"
import PngThreeMob from "../../../assets/images/raster/pages/reverse-asin-lookup/Section/reverse-section-3@mob.png"
import PngThreeMob_2x from "../../../assets/images/raster/pages/reverse-asin-lookup/Section/reverse-section-3@mob2x.png"

const PG_RAL_SECTION_CONTENT = {
  PngOne,
  PngOne_2x,
  PngOneMob,
  PngOneMob_2x,
  PngTwo,
  PngTwo_2x,
  PngTwoMob,
  PngTwoMob_2x,
  PngThree,
  PngThree_2x,
  PngThreeMob,
  PngThreeMob_2x,
}

export default PG_RAL_SECTION_CONTENT
