import React from "react";
import EmailCollector from "../EmailCollector";
import "./index.scss";

const FormSectionMiddle = () => {
  return (
    <section className="PgRAL-FormSectionMiddle">
      <EmailCollector />
    </section>
  );
};

export default FormSectionMiddle;
