import React, { useContext } from "react";
import { MediaAuthContext } from "../../../store/context/media-auth-context";
import PointIcon from "../../../assets/images/vector/components/common/group-copy.svg";
import PG_RAL_HOME_INTRO_CONTENT from "./index.content";
import EmailCollector from "../EmailCollector";
import "./index.scss";

const HomeIntro = () => {
  const {
    media: { isTabletOrLess },
    auth: { isAuth },
  } = useContext(MediaAuthContext);
  const { Png, Png2x, PngLg, PngLg1_5x, PngMob, PngMob1_5x } =
    PG_RAL_HOME_INTRO_CONTENT;

  return (
    <section className="PgRAL-HomeIntro">
      <div className="container">
        <div className="PgRAL-HomeIntro-row">
          <div className="PgRAL-HomeIntro-info">
            <h1 className="PgRAL-HomeIntro__h1">
              AMZScout Reverse ASIN Lookup
            </h1>
            <div className="PgRAL-HomeIntro__subtitle">
              Trusted by 500,000 Amazon sellers!
            </div>
            <div className="PgRAL-HomeIntro-points">
              <div className="PgRAL-HomeIntro-points__title">
                {" "}
                See all the <span>Keywords</span> a product is ranked for!
              </div>
              <div className="PgRAL-HomeIntro-points__item">
                <img src={PointIcon} alt="" />
                <div>
                  <b>Create Effective PPC Ads:</b> Quickly see all of the
                  keywords that competitors’ products rank for and use them in
                  your own PPC campaigns so that your budget is used efficiently
                </div>
              </div>
              <div className="PgRAL-HomeIntro-points__item">
                <img src={PointIcon} alt="" />
                <div>
                  <b>Boost Your Organic Traffic:</b> Find the best keywords for
                  your listing to attract new buyers and get sales
                </div>
              </div>
              <div className="PgRAL-HomeIntro-points__item">
                <img src={PointIcon} alt="" />
                <div>
                  <b>Choose Products People Want to Buy:</b> Gauge how popular a
                  product is by seeing the estimated monthly search volume of
                  its keywords
                </div>
              </div>
            </div>
            {isAuth && isTabletOrLess ? null : <EmailCollector isIntro />}
          </div>
          <div className="PgRAL-HomeIntro-screen">
            <picture>
              <source
                media="(min-width: 1200px)"
                srcSet={`${Png} 1x, ${Png2x} 2x`}
              />
              <source
                media="(min-width: 992px) and (max-width: 1199px)"
                srcSet={`${PngLg} 1x, ${PngLg1_5x} 2x`}
              />
              <source
                media="(min-width: 768px) and (max-width: 991px)"
                srcSet={`${Png} 1x, ${Png2x} 2x`}
              />
              <source
                media="(max-width: 767px)"
                srcSet={`${PngMob} 1x, ${PngMob1_5x} 2x`}
              />
              <img src={Png} alt="" />
            </picture>
          </div>
          {isAuth && isTabletOrLess ? <EmailCollector isIntro /> : null}
        </div>
      </div>
    </section>
  );
};

export default HomeIntro;
