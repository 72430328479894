import React from "react"
import Png from "../../../assets/images/raster/pages/reverse-asin-lookup/Steps/reverse-steps.png"
import Png2x from "../../../assets/images/raster/pages/reverse-asin-lookup/Steps/reverse-steps@2x.png"
import SvgOne from "../../../assets/images/vector/pages/reverse-asin-lookup/Steps/reverse-step-1.svg"
import SvgTwo from "../../../assets/images/vector/pages/reverse-asin-lookup/Steps/reverse-step-2.svg"
import SvgThree from "../../../assets/images/vector/pages/reverse-asin-lookup/Steps/reverse-step-3.svg"

const PG_RAL_STEPS_CONTENT = { Png, Png2x, SvgOne, SvgTwo, SvgThree }

export default PG_RAL_STEPS_CONTENT
