import React from "react";
import PropTypes from "prop-types";
import { PAGES } from "../../../helpers/constants";
import { getTrueClasses } from "../../../helpers";
import IntroEmailCollectContainer from "../../../containers/common/forms/IntroEmailCollectContainer";
import "./index.scss";

const EmailCollector = ({ isIntro }) => {
  return (
    <IntroEmailCollectContainer
      page={PAGES.REVERSE_ASIN}
      title="TRY Reverse ASIN Lookup For Free!"
      customClass={getTrueClasses(
        "PgRAL-EmailCollector",
        isIntro && "PgRAL-EmailCollector_intro"
      )}
      buttonTextFirstState="Try for Free!"
      buttonTextSecondState="GET REVERSE ASIN LOOKUP FOR FREE "
    />
  );
};

EmailCollector.propTypes = {
  isIntro: PropTypes.bool,
};

export default EmailCollector;
