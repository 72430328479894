import React from "react"
import Png from "../../../assets/images/raster/pages/reverse-asin-lookup/HomeIntro/reverse-hi-bg.png"
import Png2x from "../../../assets/images/raster/pages/reverse-asin-lookup/HomeIntro/reverse-hi-bg@2x.png"
import PngLg from "../../../assets/images/raster/pages/reverse-asin-lookup/HomeIntro/reverse-hi-bg@lg.png"
import PngLg1_5x from "../../../assets/images/raster/pages/reverse-asin-lookup/HomeIntro/reverse-hi-bg@lg1_5x.png"
import PngMob from "../../../assets/images/raster/pages/reverse-asin-lookup/HomeIntro/reverse-hi-bg@mob.png"
import PngMob1_5x from "../../../assets/images/raster/pages/reverse-asin-lookup/HomeIntro/reverse-hi-bg@mob1_5x.png"

const PG_RAL_HOME_INTRO_CONTENT = {
  Png,
  Png2x,
  PngLg,
  PngLg1_5x,
  PngMob,
  PngMob1_5x,
}

export default PG_RAL_HOME_INTRO_CONTENT
