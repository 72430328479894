import React from "react";
import ChromeRating from "../ChromeRating";
import PartnerBrand from "../PartnerBrand";
import "./index.scss";

const ReviewSection = () => {
  return (
    <section className="ReviewSection">
      <ChromeRating />
      <PartnerBrand />
      <div className="ReviewSection__text">
        We’re proud to see AMZScout at work in thousands of corporate and home
        offices
      </div>
    </section>
  );
};

export default ReviewSection;
