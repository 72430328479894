import React from "react";
import EmailCollector from "../EmailCollector";
import "./index.scss";

const FormSectionBottom = () => {
  return (
    <section className="PgRAL-FormSectionBottom">
      <div className="container">
        <div className="PgRAL-FormSectionBottom-info">
          <div className="PgRAL-FormSectionBottom__title">
            See what keywords your product ranks for!
          </div>
          <div className="PgRAL-FormSectionBottom__subtitle">
            Start a free trial of Reverse ASIN Lookup now and{" "}
            <span>check up to 10 products</span>
          </div>
        </div>
        <EmailCollector />
      </div>
    </section>
  );
};

export default FormSectionBottom;
