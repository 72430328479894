import React from "react";
import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import HomeIntro from "../components/reverse-asin-lookup/HomeIntro";
import Steps from "../components/reverse-asin-lookup/Steps";
import Section from "../components/reverse-asin-lookup/Section";
import OgImg from "../assets/images/raster/components/common/mainscreen_reverse_asin_lookup.png";
import ReviewSection from "../components/common/ReviewSection";
import FormSectionMiddle from "../components/reverse-asin-lookup/FormSectionMiddle";
import FormSectionBottom from "../components/reverse-asin-lookup/FormSectionBottom";

const PgRAL = () => {
  return (
    <Layout>
      <Seo
        title="Amazon Reverse ASIN Lookup Tool: Discover Competitors’ Keywords"
        description={
          "Reverse ASIN Lookup is the best tool for Amazon keyword research. Find out the list of competitors' high-rank keywords by entering their ASIN number. Try for free."
        }
        page="reverse-asin-lookup"
        manifest="browserconfig.xml"
        ogImg={OgImg}
      />
      <HomeIntro />
      <ReviewSection />
      <Steps />
      <FormSectionMiddle />
      <Section />
      <FormSectionBottom />
    </Layout>
  );
};

export default PgRAL;
