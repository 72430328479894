import React from "react";
import PG_RAL_STEPS_CONTENT from "./index.content";
import "./index.scss";

const Steps = () => {
  const { Png, Png2x, SvgOne, SvgTwo, SvgThree } = PG_RAL_STEPS_CONTENT;
  return (
    <section className="PgRAL-Steps">
      <div className="container">
        <div className="PgRAL-Steps__title">
          Get Your Keywords in 3 Quick Steps
        </div>
        <img
          srcSet={`${Png} 1x, ${Png2x} 2x`}
          className="PgRAL-Steps__img"
          alt=""
        />
        <div className="PgRAL-Steps-list">
          <div className="PgRAL-Steps-item">
            <img src={SvgOne} className="PgRAL-Steps-item__img" alt="" />
            <div className="PgRAL-Steps-item-info">
              <div className="PgRAL-Steps-item__title">Log in to AMZScout</div>
              <div className="PgRAL-Steps-item__text">
                Sign up on the AMZScout website and select “Reverse ASIN Lookup”
                from the menu
              </div>
            </div>
          </div>
          <div className="PgRAL-Steps-item">
            <img src={SvgTwo} className="PgRAL-Steps-item__img" alt="" />
            <div className="PgRAL-Steps-item-info">
              <div className="PgRAL-Steps-item__title">Enter an ASIN</div>
              <div className="PgRAL-Steps-item__text">
                Type the number of any product on Amazon into the Amazon ASIN
                Lookup tool and get your keywords
              </div>
            </div>
          </div>
          <div className="PgRAL-Steps-item">
            <img src={SvgThree} className="PgRAL-Steps-item__img" alt="" />
            <div className="PgRAL-Steps-item-info">
              <div className="PgRAL-Steps-item__title">
                Get Powerful Results
              </div>
              <div className="PgRAL-Steps-item__text">
                Receive a list of hundreds of related search terms. Use the
                available filters to narrow your search and find the perfect
                keywords.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Steps;
