import React from "react";
import PointIcon from "../../../assets/images/vector/components/common/group-copy.svg";
import PG_RAL_SECTION_CONTENT from "./index.content";
import "./index.scss";

const Section = () => {
  const {
    PngOne,
    PngOne_2x,
    PngOneMob,
    PngOneMob_2x,
    PngTwo,
    PngTwo_2x,
    PngTwoMob,
    PngTwoMob_2x,
    PngThree,
    PngThree_2x,
    PngThreeMob,
    PngThreeMob_2x,
  } = PG_RAL_SECTION_CONTENT;

  return (
    <section className="PgRAL-Section">
      <div className="container">
        <div className="PgRAL-Section-row PgRAL-Section-rowOne">
          <div className="PgRAL-Section-points">
            <div className="PgRAL-Section-points__title">
              Spy on Your Competitors{" "}
            </div>
            <div className="PgRAL-Section-points__item">
              <img src={PointIcon} alt="" />
              <div>
                <b>Track Your Competition</b>
                See where your competitor's product currently ranks for each
                keyword and what page of the search results it shows up on
              </div>
            </div>
            <div className="PgRAL-Section-points__item">
              <img src={PointIcon} alt="" />
              <div>
                <b>Get New Keyword Ideas</b>
                Find out what search terms your competitors are using to
                discover new keywords for your products
              </div>
            </div>
            <div className="PgRAL-Section-points__item">
              <img src={PointIcon} alt="" />
              <div>
                <b>Build Better Ad Campaigns</b>
                Discover what keywords your competitors are targeting and use
                them in your ad campaigns
              </div>
            </div>
          </div>
          <div className="PgRAL-Section__img">
            <picture>
              <source
                media="(min-width: 768px) "
                srcSet={`${PngOne} 1x, ${PngOne_2x} 2x`}
              />
              <source
                media="(max-width: 767px)"
                srcSet={`${PngOneMob} 1x, ${PngOneMob_2x} 2x`}
              />
              <img src={PngOne} alt="" />
            </picture>
          </div>
        </div>
        <div className="PgRAL-Section-row PgRAL-Section-rowTwo">
          <div className="PgRAL-Section-points">
            <div className="PgRAL-Section-points__title">
              Optimize Your Listing and Increase Organic Sales
            </div>
            <div className="PgRAL-Section-points__item">
              <img src={PointIcon} alt="" />
              <div>
                <b>Find the Best Keywords</b>
                Learn which terms receive the most monthly searches and include
                those in your listing
              </div>
            </div>
            <div className="PgRAL-Section-points__item">
              <img src={PointIcon} alt="" />
              <div>
                <b>Choose Relevant Terms</b>
                The relevance score tells you how relevant a keyword is to your
                product. Select the terms with the highest score so that more
                customers will see your product in search results.
              </div>
            </div>
            <div className="PgRAL-Section-points__item">
              <img src={PointIcon} alt="" />
              <div>
                <b>Track Keyword Rank</b>
                See how high a product is ranking for important keywords to plan
                your SEO strategy or borrow successful competitors' techniques
              </div>
            </div>
            <div className="PgRAL-Section-points__item">
              <img src={PointIcon} alt="" />
              <div>
                <b>Learn How to Improve</b>
                Find out how many sales your product needs in order to appear on
                the first page of Amazon’s search results
              </div>
            </div>
          </div>
          <div className="PgRAL-Section__img">
            <picture>
              <source
                media="(min-width: 768px) "
                srcSet={`${PngTwo} 1x, ${PngTwo_2x} 2x`}
              />
              <source
                media="(max-width: 767px)"
                srcSet={`${PngTwoMob} 1x, ${PngTwoMob_2x} 2x`}
              />
              <img src={PngTwo} alt="" />
            </picture>
          </div>
        </div>
        <div className="PgRAL-Section-row PgRAL-Section-rowThree">
          <div className="PgRAL-Section-points">
            <div className="PgRAL-Section-points__title">
              Get More Sales With Your PPC Campaigns
            </div>
            <div className="PgRAL-Section-points__item">
              <img src={PointIcon} alt="" />
              <div>
                <b>Get More Traffic</b>
                Target keywords with the highest estimated search volume,
                meaning you’ll get more traffic from your ads
              </div>
            </div>
            <div className="PgRAL-Section-points__item">
              <img src={PointIcon} alt="" />
              <div>
                <b>Increase Your Conversions</b>
                Use the relevance score to find more relevant terms for your
                ads. This means that clicks on your ad will result in a sale
                more often, giving you more sales and less wasted advertising
                budget.
              </div>
            </div>
            <div className="PgRAL-Section-points__item">
              <img src={PointIcon} alt="" />
              <div>
                <b>Track Product Ads</b>
                See which page of Amazon’s search results your sponsored product
                ad is showing up on to identify how effective it is
              </div>
            </div>
          </div>
          <div className="PgRAL-Section__img">
            <picture>
              <source
                media="(min-width: 768px) "
                srcSet={`${PngThree} 1x, ${PngThree_2x} 2x`}
              />
              <source
                media="(max-width: 767px)"
                srcSet={`${PngThreeMob} 1x, ${PngThreeMob_2x} 2x`}
              />
              <img src={PngThree} alt="" />
            </picture>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section;
